import changePasswordModalTemplate from '../views/change-password-modal.html';

ChangePasswordModalService.$inject = ['$modal'];

export function ChangePasswordModalService($modal) {

    function changePassword() {

        var modalInstance = $modal.open({
            animation: true,
            template: changePasswordModalTemplate,
            controller: 'ChangePasswordModalController',
        });

        return modalInstance.result;

    }

    return {
        changePassword
    }
}
