TimesheetController.$inject = ['$scope', '$translate', '$stateParams', 'Member', 'ActivityModal'];

export function TimesheetController($scope, $translate, $stateParams, Member, ActivityModal) {

    $translate('TIMESHEET_LAYOUT_ACTIVITIES_TITLE').then(function (activitiesTitle) {
        $scope.activitiesTitle = activitiesTitle;
    }, function (translationId) {
        $scope.activitiesTitle = translationId;
    });

    $translate('TIMESHEET_LAYOUT_ADD_BUTTON').then(function (addButton) {
        $scope.addButton = addButton;
    }, function (translationId) {
        $scope.addButton = translationId;
    });

    $translate('TIMESHEET_LAYOUT_DRAG_ACTIVITY_TOOLTIP').then(function (dragActivityTooltip) {
        $scope.dragActivityTooltip = dragActivityTooltip;
    }, function (translationId) {
        $scope.dragActivityTooltip = translationId;
    });

    Member.get($stateParams.userId).then(function (user) {
        $scope.selectedUser = user;

        $scope.$broadcast('user:selected', user);

        //Retrieve all user activities
        Member.getActivities(user.id).then(activities => {
            $scope.activities = activities;
        });
    });

    $scope.addActivity = function () {
        ActivityModal.add($scope.selectedUser.organizations, $scope.selectedUser).then(function (activity) {
            $scope.activities.push(activity);

            if (activity.id) {
                activity.$update();
            } else {
                activity.$save();
            }
        }, function () {
            //Modal dismissed
        });
    }

}