TimerecordService.$inject = ['$resource'];

export function TimerecordService($resource) {
    return $resource('/api/timerecords/:id', {
        id: '@id'
    }, {
        update: {
            method: 'PUT'
        },
        firstIncompleteDay: {
            method: 'GET',
            params: {
                id: 'firstIncompleteDay'
            }
        }
    });
}
