TimesheetService.$inject = [
  '$state',
  'AuthService',
  'Timerecord'
];

export function TimesheetService($state, AuthService, Timerecord) {
  
  function gotoTimesheet(currentUser) {
    if (!currentUser) {
      AuthService.me()
        .then(_gotoTimesheet);
    } else {
      _gotoTimesheet(currentUser);
    }
  }

  function _gotoTimesheet(currentUser) {
    Timerecord.firstIncompleteDay({
      memberId: currentUser.id
    }).$promise.then(function (day) {
      const theDay = moment(day.date);
      $state.go('app.timesheet.weekly', {
        userId: currentUser.id,
        year: theDay.year(),
        week: theDay.week()
      });
    });
  }

  return {
    gotoTimesheet: gotoTimesheet
  }
}
