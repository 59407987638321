ActivityReportsController.$inject = ['$scope', '$state', '$stateParams', '$location', '$filter', 'Activity', 'ActivityReport', 'PublicIdService', 'AuthService'];

export function ActivityReportsController($scope, $state, $stateParams, $location, $filter, Activity, ActivityReport, PublicIdService, AuthService) {

    $scope.sections = {};

    $scope.authenticated = AuthService.isAuthenticated();

    function init() {
        if ($scope.authenticated){
            Activity.query({ types: "BILLABLE,FIXED_PRICE,INTERNAL" }).$promise.then(function (activities) {
              $scope.activities = activities.map(function (activity) {
                return activity.name;
              });
            });
        }

        if($stateParams.activity) {
            $scope.selectedActivityName = decodeURIComponent($stateParams.activity);
            $scope.data($scope.selectedActivityName);
        }

    }

    $scope.getAccessUrl = function(){
        return $location.absUrl();
    }

    // methods
    $scope.data = function(activity) {
        if(!activity) {
            $scope.result = null;
            return
        }

        var r = ActivityReport.get(PublicIdService.withAccessid({activity:activity}, $stateParams.accessid), function() {

            $scope.sections = {};
            $scope.members = r.members;
            $scope.activity = r.activity;
            $scope.$broadcast('headerChanged');

            var result = {
                groups : [],
                durationByMember : {},
                total : 0
            };

            for(var i in r.members) {
                result.durationByMember[r.members[i].id] = 0;
            }

            var group = null;
            // group by month
            r.records.forEach(function(record){

                var date = new Date(record.date);

                if(group == null || date.getMonth() != group.month || date.getYear() != group.year) {
                    // begin a new group
                    group = {
                        month : date.getMonth(),
                        year : date.getYear(),
                        formattedDate : toMonth(date),
                        records : [],
                        durationByMember : {},
                        total : 0
                    };

                    for(var i in r.members) {
                        group.durationByMember[r.members[i].id] = 0;
                    }

                    result.groups.unshift(group);
                }

                record.formattedDate = toDate(date);
                group.records.push(record);

                for(var memberId in record.durationByMember) {
                    var v = record.durationByMember[memberId] = (record.durationByMember[memberId] / 100);
                    group.durationByMember[memberId] += v;
                    group.total+= v;
                    result.durationByMember[memberId] += v;
                    result.total+= v;
                }
            });

            // sort days within months
            result.groups = result.groups.map(function(group){

                group.records = group.records.sort(function(a, b) {
                    return b.date - a.date;
                });

                return group;
            });

            // set result
            $scope.result = result;

        }, function(err){
            if (err.status === 403){
                $state.go('start.tokenExpired');
            }
        });
    };

    $scope.selectActivity = function(selected){
        $state.go('.', { activity: selected });
    };

    var all = "all";

    var toKey = function(group) {
        return group ? group.formattedDate : all;
    };

    $scope.sectionClass = function(group) {
        return $scope.sections[toKey(group)] ? "section-opened" : "section-closed";
    };

    $scope.toggleSection = function(group) {
        var key = toKey(group);
        var value = !$scope.sections[key];
        $scope.sections[key] = value;

        if($scope.result) {

            var groups = $scope.result.groups;

            if(key == all) {
                groups.forEach(function(group) {
                    $scope.sections[group.formattedDate] = value;
                });
                $scope.sections[all] = value;
            } else {
                var allValue = false;
                groups.forEach(function(group) {
                    if($scope.sections[group.formattedDate]) {
                        allValue = true;
                    }
                });
                $scope.sections[all] = allValue;
            }
        }
    };

    var toDate = function(date) {
        return $filter('date')(date, 'longDate')
    };

    var toMonth = function(date) {
        return $filter('date')(date, 'MMMM yyyy')
    };

    // methods
    $scope.toDuration = function(record, member) {
        return (record && record.durationByMember[member.id]) || 0;
    };

    init();
}

