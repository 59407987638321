PeriodReportService.$inject = ['$resource', '$http', '$q'];

export function PeriodReportService($resource, $http, $q) {

    const resource = $resource('/api/reports/:year/:month');

    function exportXlsx(year, month) {
        
        const withMonth = month ? `/${month}` : ''; 
        const deferred = $q.defer();

        $http({
                url: `/api/exports/period/${year}${withMonth}`,
                method: "GET",
                headers: {
                    'Content-type': 'application/json'
                },
                responseType: 'arraybuffer', //THIS IS IMPORTANT
            })
            .success(function (data) {
                deferred.resolve(data);
            }).error(function (data) {
                deferred.reject(data);
            });

        return deferred.promise;
    }

    function query(params) {
        
        return resource.query(params);
    }

    return {
        query: query,
        exportXlsx: exportXlsx
    };
}

