AppController.$inject = ['$scope', '$state', 'AuthService', 'Timesheet', 'ChangePasswordModal'];

export function AppController($scope, $state, AuthService, Timesheet, ChangePasswordModal) {

    $scope.isAuthenticated = AuthService.isAuthenticated();

    if ($scope.isAuthenticated) {
        //Avoid 401 error if not authenticated
        AuthService.me().then((currentUser) => {
            $scope.currentUser = currentUser;
        });
    }

    $scope.logout = function(){
        AuthService.logout()
        $state.go('login');
    }

    $scope.changePassword = function () {
        ChangePasswordModal.changePassword()
    }

    $scope.gotoTimesheet = function(){
        if ($scope.isAuthenticated){
            Timesheet.gotoTimesheet();
        }
    }
}
