export function durationBoxDirective() {
    return {
        restrict: 'E',
        replace:true,
        transclude: true,
        scope: {
            moment : '=date',
            title: '@',
            duration: '@',
            fnDropActivity: '&onDropActivity',
            fnDragOver: '&onDragOver'
        },
        template: '<div class="duration-box" title="{{title}}"><p>{{durationDisplay}}</p>' +
                        '<div ng-transclude class="labels-container"></div>' +
                    '</div>',
        link: function(scope, element, attrs) {
            var durationInt = parseInt(attrs.duration);
            scope.durationDisplay = durationInt / 100;
            var onDropActivity = scope.fnDropActivity();
            var onDragOver = scope.fnDragOver();

            element.bind('drop', function(event){

                event.preventDefault();
                event.stopPropagation();

                event.target.classList.remove('animate');
                //jquery wrap the original event
                onDropActivity(event.originalEvent, scope.moment, durationInt);
            });

            element.bind('dragover', function(event) {
                event.preventDefault();
                event.stopPropagation();

                //jquery wrap the original event
                onDragOver(event.originalEvent, scope.moment, durationInt);
            });
            element.bind('dragenter', function(event){
                event.preventDefault();
                event.stopPropagation();
                event.target.classList.add('animate');
            });
            element.bind('dragleave', function(event){
                event.preventDefault();
                event.stopPropagation();
                event.target.classList.remove('animate');
            });
        }
    }
}
