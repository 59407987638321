export function activityDirective() {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            activity: '=ngModel',
            timerecord: '='
        },
        transclude: true,
        template: '<span id="{{activity.id}}" class="label activity-label"><span>{{activity.name}}</span><div ng-transclude></div></span>',
        link : function(scope, element) {

            //TODO refactor : create a timerecord directive to display it in timesheet and enable drag and drop
            if (scope.timerecord) {
                scope.activity = scope.timerecord.activity
            }

            switch (scope.activity.type) {
              case "PERSONAL":
                element.addClass("label-personal");
                break;
              case "INTERNAL":
                element.addClass("label-internal");
                break;
              case "BILLABLE":
                element.addClass("label-billable");
                break;
              case "FIXED_PRICE":
                element.addClass("label-fixed-price");
                break;
            }

        }
    }
}
