ChangePasswordModalController.$inject = ["$scope", "$modalInstance", "Member"];

export function ChangePasswordModalController($scope, $modalInstance, Member) {
  $scope.ok = function () {
    $scope.error = undefined;

    Member.changePassword($scope.oldPassword, $scope.newPassword).then(
      () => {
        $modalInstance.close();
      },
      (err) => {
        if (err.data.message === "Invalid oldPassword") {
          $scope.oldPasswordError = "Le mot de passe est invalide";
        } else {
          $scope.error = err.data.message;
        }
      }
    );
  };

  $scope.$watch('oldPassword', ()=> $scope.oldPasswordError = undefined)

  $scope.cancel = function () {
    $modalInstance.dismiss("cancel");
  };

  $scope.open = function () {
    $scope.status.opened = true;
  };

  $scope.status = {
    opened: false,
  };
}
