import angular from 'angular';

import { ActivityReportsController } from './controllers/activity-report.controller';
import { ReportsController } from './controllers/period-report.controller';

import { ActivityReportService } from './services/activity-report.service';
import { PeriodReportService } from './services/period-report.service';

const reports = angular.module('reports',
    [
        'ngResource',
        'ngSanitize',
        'ui.select', 
        'commons',
        'activities',
        'publicLink'
    ]
);

reports.controller('ActivityReportsController', ActivityReportsController);
reports.controller('ReportsController', ReportsController);

reports.factory('ActivityReport', ActivityReportService);
reports.factory('PeriodReport', PeriodReportService);