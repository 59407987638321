AddMemberModalController.$inject = ['$scope', '$modalInstance', 'member'];

export function AddMemberModalController($scope, $modalInstance, member) {

    $scope.member = member;

    $scope.ok = function () {
        $modalInstance.close($scope.member);
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };

    $scope.open = function() {
        $scope.status.opened = true;
    };

    $scope.status = {
        opened: false
    };

}
