OrganizationService.$inject = ['$http'];

export function OrganizationService($http) {

  function addMember(organizationShortName, orgMember){
    return $http.post(`/api/organizations/${organizationShortName}/members`, orgMember)
      .then(res => res.data);
  }

  function getMembers(organizationShortName) {
    return $http.get(`/api/organizations/${organizationShortName}/members`)
      .then(res => res.data);
  }

  function removeMember(organizationShortName, orgMember) {
    return $http.delete(`/api/organizations/${organizationShortName}/members/${orgMember.member.id}`);
  }

  function updateRole(organizationShortName, orgMember) {
    return $http.put(`/api/organizations/${organizationShortName}/members/${orgMember.member.id}/role`, { role: orgMember.role });
  }
  return {
    addMember,
    getMembers,
    removeMember,
    updateRole,
  };
}
