import publicLinkTemplate from '../views/publicLink.html';
/**
 * A generic confirmation for risky actions.
 * Usage: Add attributes: ng-really-message="Are you sure"? ng-really-click="takeAction()" function
 */
export function PublicLink() {
    return {
        restrict: 'E',
        scope: {
            apiUrl : '@apiUrl',
            publicUrl: '@publicUrl',
            publicLinkName: '=publicLinkName'
        },
        template: publicLinkTemplate,
        controller: 'PublicLinkController'
    }
}
