UpdateCommentModalController.$inject = ['$scope', '$translate', '$modalInstance', 'timerecord', 'memberId', 'Member'];

export function UpdateCommentModalController($scope, $translate, $modalInstance, timerecord, memberId, Member) {

    $translate('EDIT_COMMENT_MODAL_TITLE').then(function (editCommentTitle) {
        $scope.editCommentTitle = editCommentTitle;
    }, function (translationId) {
        $scope.editCommentTitle = translationId;
    });

    $translate('ADD_COMMENT_MODAL_TITLE').then(function (addCommentTitle) {
        $scope.addCommentTitle = addCommentTitle;
    }, function (translationId) {
        $scope.addCommentTitle = translationId;
    });

    $translate('UPDATE_COMMENT_MODAL_ADD_COMMENT_PLACEHOLDER').then(function (addCommentPlaceholder) {
        $scope.addCommentPlaceholder = addCommentPlaceholder;
    }, function (translationId) {
        $scope.addCommentPlaceholder = translationId;
    });

    $translate('VALIDATE').then(function (validateButton) {
        $scope.validateButton = validateButton;
    }, function (translationId) {
        $scope.validateButton = translationId;
    });

    $translate('CANCEL').then(function (cancelButton) {
        $scope.cancelButton = cancelButton;
    }, function (translationId) {
        $scope.cancelButton = translationId;
    });

    $scope.timerecord = timerecord;

    $scope.date = new Date(timerecord.date).toLocaleDateString($translate.use(), {
        weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'
    });

    $scope.apiActivity =

    $scope.getComments = function(commentFilter){
        return Member.getComments(memberId, commentFilter);
    }

    $scope.ok = function () {
        $modalInstance.close($scope.timerecord);
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };
}
