import 'bootstrap/dist/css/bootstrap.css';
import 'ui-select/dist/select.css';
import 'angular-toastr/dist/angular-toastr.css';
import 'spinkit/scss/spinners/3-wave.scss';

import '../styles/vars.scss';
import '../styles/fonts.scss';
import '../styles/login.scss';
import '../styles/nav.scss';
import '../styles/activityReports.scss';
import '../styles/export.scss';
import '../styles/publicLink.scss';
import '../styles/reports.scss';
import '../styles/style.scss';
import '../styles/activities.scss';

import '../styles/timesheet.scss';
import '../styles/commentModal.scss';
import '../styles/activityModal.scss';

//JS import section
import 'jquery';
import angular from 'angular';
import 'angular-ui-router';
import 'angular-resource';
import 'angular-ui-bootstrap';
import 'angular-jwt';
import 'ui-select';
import 'angular-gravatar';
import 'angular-sanitize';
import 'angular-toastr';
import 'lodash';
import 'angular-translate';
import * as moment from 'moment';

moment.locale('fr');

import '../lib/moment-ferie-fr';

import * as en from '../i18n/en';
import * as fr from '../i18n/fr';

import { AppConfiguration } from './app.config';
import './activities';
import './auth';
import './commons';
import './core';
import './home';
import './login';
import './members';
import './organizations';
import './publicLink';
import './reports';
import './timesheet';

const timerecordApp = angular.module('timerecord',
    [
        'angular-jwt',
        'ui.bootstrap',
        'ui.router',
        'toastr',
        'pascalprecht.translate',
        'activities',
        'auth',
        'commons',
        'core',
        'home',
        'login',
        'members',
        'organizations',
        'timesheet',
        'reports',
    ]
);

timerecordApp.config([
    '$stateProvider',
    '$urlRouterProvider',
    '$httpProvider',
    '$locationProvider',
    'jwtOptionsProvider',
    AppConfiguration
]);

timerecordApp.config(['$translateProvider', function ($translateProvider) {
    // add translation table
    $translateProvider
        .translations('en', en.translations)
        .translations('fr', fr.translations)
        .preferredLanguage('fr');

    // Enable escaping of HTML
    $translateProvider.useSanitizeValueStrategy('escape');
}]);

export const TimerecordAppModule = timerecordApp;