export var translations = {
    TIMESHEET_LAYOUT_ACTIVITIES_TITLE: 'Activités',
    TIMESHEET_LAYOUT_ADD_BUTTON: 'Ajouter',
    TIMESHEET_LAYOUT_DRAG_ACTIVITY_TOOLTIP: 'Glisse ton activité sur une période',
    WEEKLY_TIMESHEET_WEEK: 'Semaine',
    WEEKLY_TIMESHEET_WEEK_INCOMPLETE: 'Première semaine non complète du mois',
    WEEKLY_TIMESHEET_HOLIDAY: 'Jour férié',
    WEEKLY_TIMESHEET_EQUIVALENT: 'Equivalent',
    UPDATE_COMMENT_MODAL_ADD_MODIFY_COMMENT_TITLE: 'Ajouter / Modifier un commentaire',
    EDIT_COMMENT_MODAL_TITLE: 'Modifier votre commentaire',
    ADD_COMMENT_MODAL_TITLE: 'Ajouter un commentaire',
    UPDATE_COMMENT_MODAL_ADD_COMMENT_PLACEHOLDER: 'Ajouter un commentaire',
    VALIDATE: 'Valider',
    CANCEL: 'Annuler'
};