import angular from 'angular';

import { TimesheetController } from './controllers/timesheet.controller';
import { UpdateCommentModalController } from './controllers/update-comment-modal.controller';
import { WeeklyTimesheetController } from './controllers/weekly-timesheet.controller';

import { draggableTimerecordDirective } from './directives/draggable-timerecord.directive';
import { durationBoxDirective } from './directives/duration-box.directive';
import { trashDirective } from './directives/trash.directive';
import { weekBoxDirective } from './directives/week-box.directive';

import { DayFilter } from './filters/day.filter';
import { DurationFilter } from './filters/duration.filter';

import { TimerecordService } from './services/timerecord.service';
import { TimesheetService } from './services/timesheet.service';

const timesheet  = angular.module('timesheet',
    [
        'ngResource',
        'ui.bootstrap',
        'toastr',
        'auth',
        'commons',
        'activities',
        'members'
    ]
);

timesheet.controller('TimesheetController', TimesheetController);
timesheet.controller('UpdateCommentModalController', UpdateCommentModalController);
timesheet.controller('WeeklyTimesheetController', WeeklyTimesheetController);

timesheet.directive('draggableTimerecord', draggableTimerecordDirective);
timesheet.directive('durationBox', durationBoxDirective);
timesheet.directive('trash', trashDirective);
timesheet.directive('weekBox', weekBoxDirective);

timesheet.filter('day', DayFilter);
timesheet.filter('duration', DurationFilter);

timesheet.factory('Timerecord', TimerecordService);
timesheet.factory('Timesheet', TimesheetService);

export const TimesheetModule = timesheet;