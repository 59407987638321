import angular from 'angular';

export function activityLegendDirective() {
    return {
      restrict: "A",
      transclude: true,
      template:
        "<div>" +
        "<div ng-transclude></div>" +
        '<div class="legend">' +
        "<span>Légende</span>" +
        '<div class="label label-billable">Facturable</div>' +
        '<div class="label label-fixed-price">Forfait</div>' +
        '<div class="label label-internal">Interne</div>' +
        '<div class="label label-personal">Personnel</div>' +
        "</div>" +
        "</div>",
      link: function (scope, element) {
        element.bind("mouseenter", function () {
          angular.element(document.querySelector(".legend")).addClass("show");
        });

        element.bind("mouseleave", function () {
          angular.element(document.querySelector(".legend")).removeClass("show");
        });
      },
    };
}
