import angular from 'angular';

import { PublicLinkController } from './controllers/publicLink.controller';
import { PublicLink } from './directives/publicLink.directive';
import { PublicIdService } from './services/publicId.service';
import { PublicLinkService } from './services/publicLink.service';

const publicLink = angular.module('publicLink', []);

publicLink.controller('PublicLinkController', PublicLinkController);

publicLink.directive('publicLink', PublicLink);

publicLink.factory('PublicIdService', PublicIdService);
publicLink.factory('PublicLink', PublicLinkService);

export const PublicLinkModule = publicLink;