export function PublicIdService() {
    return {
        // access id retrieve
        withAccessid : function(obj, accessid) {
            if(accessid)
                obj.accessid = accessid;
            return obj;
        }
    };
}
