export var translations = {
  TIMESHEET_LAYOUT_ACTIVITIES_TITLE: 'Activities',
  TIMESHEET_LAYOUT_ADD_BUTTON: 'Add',
  TIMESHEET_LAYOUT_DRAG_ACTIVITY_TOOLTIP: 'Drag your activity on a period',
  WEEKLY_TIMESHEET_WEEK: 'Week',
  WEEKLY_TIMESHEET_WEEK_INCOMPLETE: 'First incomplete week of the month',
  WEEKLY_TIMESHEET_HOLIDAY: 'Holiday',
  WEEKLY_TIMESHEET_EQUIVALENT: 'Equivalent',
  UPDATE_COMMENT_MODAL_ADD_MODIFY_COMMENT_TITLE: 'Add / Modify a comment',
  EDIT_COMMENT_MODAL_TITLE: 'Edit your comment',
  ADD_COMMENT_MODAL_TITLE: 'Add a comment',
  UPDATE_COMMENT_MODAL_ADD_COMMENT_PLACEHOLDER: 'Add a comment',
  VALIDATE: 'Validate',
  CANCEL: 'Cancel'
};