ReportsController.$inject = ['$scope', '$state', '$stateParams', '$filter', 'CalendarService', 'PeriodReport', 'me'];

export function ReportsController($scope, $state, $stateParams, $filter, CalendarService, PeriodReport, me) {

    var now = new Date();

    $scope.period = $stateParams.period;

    const organization = me.organizations[me.organizations.length - 1];
    $scope.selected = {
        year: now.getFullYear(),
        organizationId: organization.id
    }

    $scope.years = CalendarService.getYears();
    $scope.months = CalendarService.getMonths();
    $scope.monthsLabels = CalendarService.getMonthsLabels();

    if ($scope.period === 'monthly'){
        $scope.selected.month = now.getMonth() + 1;
    }

    function notCompletes(report){
        var uncompleted = $filter('filter')(report, function(value){
            return value.absences + value.joursTravailles < value.total;
        });

        return uncompleted;
    }

    $scope.refreshReport = function refreshReport() {
        
        PeriodReport.query($scope.selected).$promise.then(function(report){
            $scope.periodReports = report;
            $scope.completed = (report.length - notCompletes(report).length) + "/" + report.length;
        });
    }

    $scope.refreshReport();

    $scope.getFile = function () {
        PeriodReport.exportXlsx($scope.selected.year, $scope.selected.month)
            .then(function (data) {
                $scope.reportBlob = new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
            }, function () {
                $scope.reportBlob = [];
            });
    };

    $scope.getExportFileName = function(){
        const withMonth = $scope.period === 'monthly' ? `-${$scope.selected.month}` : '';
        return `${$scope.selected.year}${withMonth}-Report.xls`
    }

    $scope.order = function order(orderColumn) {
            if ($scope.orderColumn && $scope.orderColumn.indexOf('+') === 0){
            $scope.orderColumn = '-' + orderColumn;
            }else{
            $scope.orderColumn = '+' + orderColumn;
            }
    }

    $scope.gotoTimesheet = function(memberId){

        var year = $scope.selected.year || moment().year();
        //Take the first week of the month
        var week = moment({ year: year, month: ($scope.selected.month || 1) - 1 , day: 1}).weeks();

        $state.go('app.timesheet.weekly', { userId: memberId, year: $scope.selected.year, week: week })

    }
}


