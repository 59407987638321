
HttpErrorsObserver.$inject = ['$q', '$location', 'toastr'];

export function HttpErrorsObserver($q, $location, toastr){

    return {
        'responseError': function(errorResponse) {
            switch (errorResponse.status) {
            case 401:
                if (errorResponse.config.url !== '/api/account'){
                    $location.url('/login?error=' + window.encodeURIComponent("Vous n'êtes pas connecté"));
                }
                break;
            case 403:
                toastr.error("Vous n'êtes pas autorisé à réaliser cette action. Contacter votre administrateur");
                break;
            case 500:
                toastr.error("Une erreur s'est produite : " + errorResponse);
                break;
            }
            return $q.reject(errorResponse);
        }
    };
}
