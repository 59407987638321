export function CalendarService(){

    moment.locale('fr')

    function isToday(dayMoment){
        return dayMoment.isSame(moment(), 'day');
    }

    function isHoliday(dayMoment){
        var localMoment = moment({year: dayMoment.year(), month: dayMoment.month(), day: dayMoment.date()})
        return localMoment.isFerie();
    }

    function getWeek(year, weekOfYear){
        var week = {
            year : year,
            number : weekOfYear,
            days : {}
        }

        for (var i = 1; i <= 5; i++) {
            var dayMoment = this.getWeekOfYear(year, weekOfYear).day(i);
            week.days[i] = {
                title: dayMoment.format('ddd D MMM'),
                moment: dayMoment,
                isHoliday: isHoliday(dayMoment),
                isToday: isToday(dayMoment)
            }
        }

        return week;
    }

    function getYears(){
        var years = [];

        var currentYear = new Date().getFullYear();

        for (var i = 2018; i <= currentYear + 1; i++){
            years.push(i);
        }

        return years;
    }

    function getMonths(){
        return [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12 ];
    }

    /** Retrieve the list month labels */
    function getMonthsLabels(){
        return moment.months();
    }

    function getWeekOfYear(year, week){

        var mondayOfYear = moment.utc({year: year}).day(1);

        //Manage particular case : first week of the year is the week after
        //if first day is friday, saturday or sunday, ie monday.date() <= 28 && monday.date() >= 26
        var weekOfYear;
        if (mondayOfYear.date() <= 28 && mondayOfYear.date() >=26){
            //Use add(x, 'weeks') instead of week() function, due to a momentjs bug
            //https://github.com/moment/moment/issues/2496
            weekOfYear = mondayOfYear.add(week, 'weeks');
        }else{
            weekOfYear = mondayOfYear.add(week - 1, 'weeks')
        }

        return weekOfYear;
    }

    function isPastMonth(aMoment){
        var thisMonth = moment().startOf('month');

        return aMoment.isBefore(thisMonth);
    }

    return {
        getWeek: getWeek,
        getYears: getYears,
        getMonths: getMonths,
        getMonthsLabels: getMonthsLabels,
        getWeekOfYear: getWeekOfYear,
        isPastMonth: isPastMonth
    }
}
