AuthService.$inject = ['$http', '$log', '$q', 'Member'];

export function AuthService($http, $log, $q, Member) {

    let currentUser = null;

    function login(credentials) {
        const deferred = $q.defer();

        $http.post('/api/auth', credentials)
            .then((authResult) => {
                setSession(authResult.data);
                deferred.resolve(true);
            }, (error) => {
                $log.error(error);
                deferred.reject(error);
            })
        
        return deferred.promise;
    }

    function me() {
        const deferred = $q.defer();

        if (currentUser) {
            deferred.resolve(currentUser);
        } else {
            Member.me().then((user) => {
                currentUser = user;
                deferred.resolve(currentUser);
            });
        }
        return deferred.promise;
    }

    function setSession(authResult) {
        localStorage.setItem('access_token', authResult.accessToken);
        localStorage.setItem('expires_at', authResult.expiresAt);
    }

    function logout() {
        currentUser = null;
        // Remove tokens and expiry time from localStorage
        localStorage.removeItem('access_token');
        localStorage.removeItem('expires_at');
    }

    function isAuthenticated() {
        // Check whether the current time is past the 
        // access token's expiry time
        let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
        return new Date().getTime() < expiresAt;
    }

    return {
        login: login,
        logout: logout,
        isAuthenticated: isAuthenticated,
        me: me
    }
}