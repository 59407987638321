export function FileDownload() {
    return {
        restrict: 'A',
        scope: {
            fileDownload: '=',
            fileName: '=',
        },

        link: function (scope) {

            scope.$watch('fileDownload', function (newValue) {

                if (newValue != undefined && newValue != null) {

                    if (navigator.msSaveOrOpenBlob) {
                        navigator.msSaveOrOpenBlob(scope.fileDownload, scope.fileName);
                    } else {
                        const windowUrl = (window.URL || window.webkitURL)
                        const fileURL = window.URL.createObjectURL(scope.fileDownload);

                        const anchor = document.createElement("a"); //create a new  <a> tag element
                        anchor.download = scope.fileName;
                        anchor.href = fileURL;
                        anchor.click();
                        windowUrl.revokeObjectURL(fileURL);
                    }

                }
            });

        }
    }
}
