export function DurationFilter(){
    return function(elements, duration) {
        var filtered = [];
            for (var i = 0; i < elements.length; i++){
                if(elements[i].duration == duration)
                    filtered.push(elements[i]);
            }
        return filtered;
        };
}
