PublicLinkController.$inject = ['$scope', 'PublicLink'];

export function PublicLinkController($scope, PublicLink) {

    $scope.$watch('publicLinkName', function(newValue) {
        $scope.publicLink = null;

        if(newValue) {
            $scope.getPublicLink();
        }
    });

    function withAccessUrl(access){
        if (access.accessId){
            access.url = $scope.publicUrl + '?accessid=' + access.accessId;
        }
        return access;
    }

    $scope.getPublicLink = function() {
        var r = PublicLink.get({name: "ActivityReports-" + $scope.publicLinkName}, function() {
            $scope.publicLink = withAccessUrl(r);
        }, function() {
            $scope.publicLink = null;
        });
    };

    $scope.createPublicLink = function() {
        var r= PublicLink.save({
            name: "ActivityReports-" + $scope.publicLinkName,
            url: '/api/activityReports/' + window.encodeURIComponent($scope.publicLinkName)
        }, function(){
            $scope.publicLink = withAccessUrl(r);// use a callback to avoid blinking
        });
    };
}

