import newActivityModalTemplate from '../views/new-activity-modal.html';
import updateActivityModalTemplate from '../views/update-activity-modal.html';

export function ActivityModalService($modal) {

    function addActivity(organizations, member) {

        var modalInstance = $modal.open({
            animation: true,
            template: newActivityModalTemplate,
            windowClass : "new-activity-modal",
            controller: 'NewActivityModalController',
            resolve: {
                member: () => member,
                organizations: () => organizations
            }
        });

        return modalInstance.result;

    }

    function editActivity(organizations, activity) {

        var modalInstance = $modal.open({
            animation: true,
            template: updateActivityModalTemplate,
            controller: 'UpdateActivityModalController',
            windowClass : "update-activity-modal",
            resolve: {
                activity: () => activity,
                organizations: () => organizations
            }
        });

        return modalInstance.result;

    }

    return {
        add: addActivity,
        edit: editActivity
    }
}