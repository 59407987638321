import homeTemplate from './home/home.html';
import loginTemplate from './login/login.html';
import appTemplate from './core/views/app.html';
import timesheetLayoutTemplate from './timesheet/views/timesheet-layout.html';
import weeklyTimesheetTemplate from './timesheet/views/weekly-timesheet.html';
import activitiesTemplate from './activities/views/activities.html';
import membersTemplate from './members/views/members.html';
import activityReportTemplate from './reports/views/activityReport.html';
import periodReportTemplate from './reports/views/periodReport.html';
import { MeResolver } from './auth/me.resolver';

export function AppConfiguration($stateProvider, $urlRouterProvider, $httpProvider, $locationProvider, jwtOptionsProvider) {

    $locationProvider.html5Mode(true).hashPrefix("");


    $stateProvider

        .state('home', {
            url: '/',
            template: homeTemplate,
            controller: 'HomeController'
        })

        .state('login', {
            url: '/login?error',
            template: loginTemplate,
            controller: 'LoginController'
        })

        .state('app', {
            url: '/app',
            abstract: true,
            template: appTemplate,
            controller: 'AppController'
        })

        .state('app.timesheet', {
            url: '/timesheet/:userId',
            template: timesheetLayoutTemplate,
            controller: 'TimesheetController',
            authenticate: true
        })

        .state('app.timesheet.weekly', {
            url: '/:year/:week',
            template: weeklyTimesheetTemplate,
            controller: 'WeeklyTimesheetController',
            authenticate: true
        })

        .state('app.activities', {
            url: '/activities',
            template: activitiesTemplate,
            controller: 'ActivitiesController',
            authenticate: true
        })

        .state('app.members', {
            url: '/members',
            template: membersTemplate,
            controller: 'MembersController',
            authenticate: true
        })

        .state('app.activityReport', {
            url: '/activityReports/:activity?accessid',
            template: activityReportTemplate,
            controller: 'ActivityReportsController',
            authenticate: true
        })

        .state('app.periodReport', {
            url: '/reports/period/:period',
            template: periodReportTemplate,
            controller: 'ReportsController',
            authenticate: true,
            resolve: {
                me: MeResolver
            }
        });

    jwtOptionsProvider.config({
        tokenGetter: function() {
            return localStorage.getItem('access_token');
        }
    });
  
    $httpProvider.interceptors.push('jwtInterceptor');

    $urlRouterProvider.otherwise('/');

    $httpProvider.interceptors.push('HttpErrorsObserver');
}
