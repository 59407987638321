import angular from 'angular';

NewActivityModalController.$inject = ['$scope', '$modalInstance', 'member', 'Activity', 'organizations'];

export function NewActivityModalController($scope, $modalInstance, member, Activity, organizations) {

    $scope.member = member;

    $scope.organizations = organizations;

    $scope.activity = new Activity({
        id: "",
        name: "",
        organization: organizations[0],
        type: "BILLABLE",
        members: [],
        reference: "",
        workload: null,
    });

    $scope.activities = Activity.query();

    $scope.activitySelected = function(activity){
        //Copy activity to avoid modifying activities scope variable
        $scope.activity = angular.copy(activity);
        $scope.selectedActivity = activity;
    }

    $scope.checkSelection = function(activity){
        //Clear id and members when an existing activity is no more selected
        if ($scope.selectedActivity && $scope.selectedActivity.name != activity.name){
            activity.id = '';
            activity.members= [];
        }
    }

    $scope.ok = function () {
        //Capitalize the activity name
        $scope.activity.name.capitalize();
        
        if (member){
            if (!$scope.activity.members){
                $scope.activity.members = [];
            }
            $scope.activity.members.push(member);
        }

        $modalInstance.close($scope.activity);
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };

    $scope.canBeLinkedToMember = function(activity) {

        if (! activity.id) {
            return false;
        }

        if (typeof activity.members === "undefined") {
            return true;
        } else {
            return (! (activity.members.find(m => m.id === member.id)) );
        }
    }
}