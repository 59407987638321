import angular from 'angular';

import { Configuration } from './config';
import { FileDownload } from './directives/file-download.directive';
import { FixHeaderDirective } from './directives/fixed-header.directive';
import { NgReallyClick } from './directives/ng-really-click.directive';
import { CalendarService } from './services/calendar.service';
import { PasswordVerify } from './directives/password-verify.directive';

const commons = angular.module('commons', []);

commons.config(Configuration);

commons.directive('fileDownload', FileDownload);
commons.directive('fixHeader', FixHeaderDirective);
commons.directive('ngReallyClick', NgReallyClick);
commons.directive('passwordVerify', PasswordVerify)

commons.factory('CalendarService', CalendarService);

String.prototype.capitalize = function () {
  return this.replace(/^./, function (match) {
    return match.toUpperCase();
  });
};

export const CommonsModule = commons;