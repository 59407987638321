export function DayFilter(){
    return function(elements, day) {
        var filtered = [];

        if (elements){
            for (var i = 0; i < elements.length; i++){
                if(moment(elements[i].date).day() == day){
                    filtered.push(elements[i]);
                }
            }
        }
        return filtered;
        };
}
