//CSS import section
// import './css/theme.css';

//Project js section
import angular from 'angular';
import { TimerecordAppModule } from './app/app.module';
import { bootstrapApp } from './app/app.bootstrap';
import './app/app.config';

TimerecordAppModule.run(bootstrapApp);

function bootstrapApplication() {
  angular.element(document).ready(function () {
    angular.bootstrap(document, ['timerecord'], {
      strictDi: true
    });
  });
}

bootstrapApplication()