import angular from 'angular';

import {
  HomeController
} from './home.controller';

const home = angular.module('home', ['ui.router', 'commons', 'auth']);

home.controller('HomeController', HomeController);

export const HomeModule = home;