import angular from 'angular';

import { ActivitiesController } from './controllers/activities.controller';
import { NewActivityModalController } from './controllers/new-activity-modal.controller';
import { UpdateActivityModalController } from './controllers/update-activity-modal.controller';

import { activityLegendDirective } from './directives/activity-legend.directive';
import { activityDirective } from './directives/activity.directive';
import { draggableActivityDirective } from './directives/draggable-activity.directive';

import { ActivityModalService } from './services/activity-modal.service';
import { ActivityService } from './services/activity.service';

const activities = angular.module('activities', 
    [
        'ui.bootstrap',
        'commons',
        'members'
    ]
);

activities.controller('ActivitiesController', ActivitiesController);
activities.controller('NewActivityModalController', NewActivityModalController);
activities.controller('UpdateActivityModalController', UpdateActivityModalController);

activities.directive('activityLegend', activityLegendDirective);
activities.directive('activity', activityDirective);
activities.directive('draggableActivity', draggableActivityDirective);

activities.factory('Activity', ['$resource', ActivityService]);
activities.factory('ActivityModal', ['$modal', ActivityModalService]);

export const ActivitiesModule = activities;