UpdateActivityModalController.$inject = ['$scope', '$modalInstance', 'Organization', 'activity', 'organizations'];

export function UpdateActivityModalController($scope, $modalInstance, Organization, activity, organizations) {


    if (!activity.members){
        activity.members = [];
    }

    $scope.activity = activity;
    $scope.organizations = organizations;
    $scope.membersFiltered = () => [];

    Organization.getMembers($scope.activity.organization.shortName)
        .then(function(orgMembers) {
            $scope.members = orgMembers.map(orgMember => {
                const member = orgMember.member
                //TODO : fix this on backend. Organizations should not be serialized
                delete member.organizations;
                return member;
            });
        });

    $scope.ok = function () {
        $modalInstance.close($scope.activity);
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };

}
