draggableTimerecordDirective.$inject = ['$parse'];

export function draggableTimerecordDirective($parse) {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            attrs.$set('draggable', 'true');

            element.bind('dragstart', function(event) {

                //This is required as jquery wrap the original event
                var theEvent = event.originalEvent;

                theEvent.dataTransfer.effectAllowed = 'move';
                var timerecord = $parse(attrs.timerecord)(scope);
                timerecord.dragged = true;
                scope.$apply();

                var jsonTimerecord = JSON.stringify(timerecord);

                var type = "application/x-timerecord.timerecord+json";

                try {
                    theEvent.dataTransfer.setData(type, jsonTimerecord);
                } catch(e) {
                    // only text is supported (IE workarround => include type in data)
                    theEvent.dataTransfer.setData('Text', type + "\t" + jsonTimerecord);
                }

                return true;
            });

            element.bind('dragend', function(){
                var timerecord = $parse(attrs.timerecord)(scope);
                timerecord.dragged = false;
                scope.$apply();
            })

        }
    }

}
