draggableActivityDirective.$inject = ['$rootScope', '$parse'];

export function draggableActivityDirective($rootScope, $parse) {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function(scope, element, attrs) {
            attrs.$set('draggable', 'true');

            element.bind('dragstart', function(event) {

                //This is required as jquery wrap the original event
                var theEvent = event.originalEvent;

                //Broadcast event to get interactive event (display week-box)
                $rootScope.$broadcast('activity:drag-start');

                theEvent.dataTransfer.effectAllowed = 'copy';
                var jsonActivity = JSON.stringify($parse(attrs.ngModel)(scope));

                var type = "application/x-timerecord.activity+json";

                try {
                    theEvent.dataTransfer.setData(type, jsonActivity);
                } catch(e) {
                    // only text is supported (IE workarround => include type in data)
                    theEvent.dataTransfer.setData('Text', type + "\t" + jsonActivity);
                }

                return true;
            });

            element.bind('dragend', function() {
                //Broadcast event to get interactive event (hide week-box)
                $rootScope.$broadcast('activity:drag-end');
            });
        }
    }

}
