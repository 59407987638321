bootstrapApp.$inject = ['$rootScope', '$state', 'AuthService']

export function bootstrapApp($rootScope, $state, AuthService) {
    
    // Redirect to login if not logged in
    $rootScope.$on('$stateChangeStart', function (event, toState) {
        if (toState.name !== 'login' && !AuthService.isAuthenticated()) {
            $state.go('login');
        }

    });

}
