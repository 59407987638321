import angular from 'angular';

import { AppController } from './controllers/app.controller';

import { HttpErrorsObserver } from './services/http-errors-observer.service';

const core = angular.module('core', ['ui.router', 'ui.gravatar', 'commons']);

core.controller('AppController', AppController);
core.factory('HttpErrorsObserver', HttpErrorsObserver);

export const CoreModule = core;