import angular from 'angular';

ActivitiesController.$inject = ['$scope', 'Activity', 'ActivityModal', 'AuthService'];

export function ActivitiesController($scope, Activity, ActivityModal, AuthService) {

    Activity.query().$promise.then(activities => {
        $scope.activities = activities;
        const organizations = [];
        const map = new Map();
        for (const organization of activities.map(activity => activity.organization)) {
            if(!map.has(organization.id)) {
                map.set(organization.id, true);
                organizations.push({
                    id: organization.id,
                    name: organization.name
                });
            }
        }
        $scope.organizations = organizations;
    });

    $scope.byOrganization = (organization) => {
        return function(activity) {
            return activity.organization.id === organization.id;
        }
    };

    $scope.addActivity = function () {
        AuthService.me().then(me => {
            ActivityModal.add(me.organizations).then(function (activity) {
                $scope.activities.push(activity);
                activity.$save();
            }, function () {
                //Modal dismissed
            });
        })
    }

    $scope.remove = function(activity) {
        Activity.remove({id:activity.id}, function() {
            var idx = $scope.activities.indexOf(activity);
            $scope.activities.splice(idx, 1);
        });
    };

    $scope.edit = function(activity) {
        var updatedActivity = angular.copy(activity);
        AuthService.me().then(me => {
            ActivityModal.edit(me.organizations, updatedActivity).then(function (result) {
                var idx = $scope.activities.indexOf(activity);
                $scope.activities.splice(idx, 1, result);
                result.$update();
            }, function () {
            //Modal dismissed
            });
        });
    }

    $scope.isPersonal = function(activity) {
        return (activity.type=='PERSONAL');
    }


}