import addMemberModalTemplate from '../views/add-member-modal.html';

MemberModalService.$inject = ['$modal'];

export function MemberModalService($modal) {

    function addMember(member) {

        var modalInstance = $modal.open({
            animation: true,
            template: addMemberModalTemplate,
            controller: 'AddMemberModalController',
            resolve:{
                member: function(){
                    return member;
                }
            }
        });

        return modalInstance.result;

    }

    return {
        addMember
    }
}
