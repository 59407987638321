MembersController.$inject = ['$scope', 'MemberModal', 'AuthService', 'Organization'];

export function MembersController($scope, MemberModal, AuthService, Organization) {

    //TODO Should retrieve the organization shortName from the route parameters
    AuthService.me()
        .then((me) => {
            $scope.me = me;
            $scope.organization = me.organizations[me.organizations.length - 1];

            return Organization.getMembers($scope.organization.shortName)
        })
        .then((orgMembers) => {
            $scope.orgMembers = orgMembers;
        });

    $scope.addMember = function () {

        MemberModal.addMember().then(function (member) {
            let newOrgMember = {
                member,
                role: 'Member'
            }

            Organization.addMember($scope.organization.shortName, newOrgMember)
                .then(orgMember => {
                    $scope.orgMembers.push(orgMember);
                });

        });
    }

    $scope.removeMember = function (orgMember) {
        let idx = $scope.orgMembers.indexOf(orgMember);
        $scope.orgMembers.splice(idx, 1);
        Organization.removeMember($scope.organization.shortName, orgMember);
    }

    $scope.updateRole = function (orgMember) {
        Organization.updateRole($scope.organization.shortName, orgMember);
    }
}
