export function weekBoxDirective() {
    return {
        restrict: 'E',
        replace:true,
        scope: {
            title: '@',
            fnDropActivity: '&onDropActivity',
            fnDragOver: '&onDragOver'
        },
        template: '<div class="week-box">' +
                        '<p>Semaine entière</p>' +
                    '</div>',

        link: function(scope, element) {
            var onDropActivity = scope.fnDropActivity();
            var onDragOver = scope.fnDragOver();

            scope.$on('activity:drag-start', function(){
                element.addClass('show', 750);
            });

            scope.$on('activity:drag-end', function(){
                element.removeClass('show', 750);
            });

            element.bind('drop', function(event){

                event.preventDefault();
                event.stopPropagation();

                event.target.classList.remove('drag');
                //jquery wrap the original event
                onDropActivity(event.originalEvent);
            });

            element.bind('dragover', function(event) {
                event.preventDefault();
                event.stopPropagation();

                //jquery wrap the original event
                onDragOver(event.originalEvent);
            });
            element.bind('dragenter', function(event){
                event.preventDefault();
                event.stopPropagation();
                event.target.classList.add('drag');
            });
            element.bind('dragleave', function(event){
                event.preventDefault();
                event.stopPropagation();
                event.target.classList.remove('drag');
            });
        }
    }
}
