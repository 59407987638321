import angular from 'angular';

FixHeaderDirective.$inject = ['$window', '$timeout'];

export function FixHeaderDirective($window, $timeout) {

    function setFixedHeader(id) {

        //Unbind listener on scroll event
        angular.element($window).unbind('scroll');

        var oldScrollTop;

        function scrollListener() {

            //The element is not displayed anymore. Remove the listener
            if (angular.element("#"+id+"").length == 0){
                angular.element($window).unbind('scroll');
            }else{
                var tableOffsetLeft = angular.element("#"+id+"").offset().left;
                var hOffset = tableOffsetLeft - angular.element(document).scrollLeft();

                if (oldScrollTop == angular.element(window).scrollTop()){
                    //Scroll horizontally

                    if (angular.element(document).scrollLeft() > 0 && angular.element("#"+id+" > thead.header-fixed").length > 0){
                        angular.element("#"+id+" > thead.header-fixed").css("left", hOffset)
                    }
                } else {
                    //Scroll vertically
                    oldScrollTop = angular.element(window).scrollTop();

                    var tableOffset = angular.element("#"+id+"").offset().top;
                    var vOffset = Math.max(navBar.offset().top + navBar[0].offsetHeight, angular.element(document).scrollTop());


                    if (vOffset >= tableOffset && angular.element("#"+id+" > thead.header-fixed").length == 0) {

                        angular.element("#"+id+" > thead:last").addClass("header-fixed").before(fakeHeader);
                        angular.element("#"+id+" > thead.header-fixed").css("width", angular.element("#"+id+" > thead:first").css("width")).css("left", hOffset).offset({top:vOffset});

                        var originalTh = angular.element("#"+id+" > thead:first th");
                        var fixedTh = angular.element("#"+id+" > thead.header-fixed th");

                        for(var i=0; i < originalTh.length; ++i) {
                            angular.element(fixedTh[i]).css("width", (originalTh[i].offsetWidth)+"px");
                        }

                    } else if (vOffset < tableOffset) {
                        fakeHeader.remove();
                        angular.element("#"+id+" > thead.header-fixed").removeClass("header-fixed");
                    }
                }
            }
        }

        if (angular.element(".header-fixed").length == 0) {
            var fakeHeader = angular.element("#"+id+" > thead").clone();
            var navBar = angular.element("nav");

            angular.element($window).bind('scroll', scrollListener);
        }
    }

    return function ($scope, element, attrs) {

        setFixedHeader(attrs.id)
        $scope.$on('headerChanged', function() {
            $timeout(function(){
                setFixedHeader(attrs.id);
            });
        });
    }
}

