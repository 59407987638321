MemberService.$inject = ["$http", "Activity"];

export function MemberService($http, Activity) {
  function me() {
    return $http.get("/api/members/me").then((res) => res.data);
  }

  function changePassword(oldPassword, newPassword) {
    return $http.put("/api/members/me/change-password", { oldPassword, newPassword }).then((res) => res.data);
  }

  function get(memberId) {
    return $http.get(`/api/members/${memberId}`).then((res) => res.data);
  }

  function getActivities(memberId) {
    return $http.get(`/api/members/${memberId}/activities`).then((res) => {
      const activities = res.data;
      return activities.map((activity) => {
        return new Activity(activity);
      });
    });
  }

  function getComments(memberId, filter) {
      return $http.get(`/api/members/${memberId}/comments?filter=${filter}`).then((res) => res.data.slice(0, 10));
  }

  return {
    me,
    changePassword,
    get,
    getActivities,
    getComments,
  };
}
