HomeController.$inject = [
  '$state',
  '$timeout',
  'AuthService',
  'Timesheet'
];

export function HomeController($state, $timeout, AuthService, Timesheet) {

  $timeout(() => {
    if (AuthService.isAuthenticated()) {
      Timesheet.gotoTimesheet();
    } else {
      $state.go('login');
    }
  }, 1000);

  
}