import angular from 'angular';

import updateCommentModalTemplate from '../views/update-comment-modal.html'

function toDragType(ev) {
    var type = ev.dataTransfer.types[ev.dataTransfer.types.length - 1];
    var data = ev.dataTransfer.getData(type);

    if(type=='Text') {
        // IE workarround => include type in data
        var index = data.indexOf('\t');
        if (index > 0) {
            type = data.substring(0, index);//extract type from text
        }
    }
    return type;
}

function toDragDataAndType(ev) {
    var type = ev.dataTransfer.types[ev.dataTransfer.types.length - 1];
    var data = ev.dataTransfer.getData(type);

    if(type=='Text') {
        // IE workarround => include type in data
        var index = data.indexOf('\t');
        if (index > 0) {
            type = data.substring(0, index);//extract type from text
            data = data.substring(index+1);
        }
    }
    try {
        data = JSON.parse(data);
    } catch(e) {
        type = 'Text';//not json
    }
    return {data:data, type:type};
}

WeeklyTimesheetController.$inject = ['$scope', '$translate', '$filter', '$state', '$stateParams', '$timeout', 'CalendarService', 'Timerecord', 'toastr', '$modal', 'AuthService'];

export function WeeklyTimesheetController($scope, $translate, $filter, $state, $stateParams, $timeout, CalendarService, Timerecord, toastr, $modal, AuthService) {

    $translate('WEEKLY_TIMESHEET_WEEK').then(function (week_title) {
        $scope.week_title = week_title;
    }, function (translationId) {
        $scope.week_title = translationId;
    });

    $translate('WEEKLY_TIMESHEET_WEEK_INCOMPLETE').then(function (weekIncomplete) {
        $scope.weekIncomplete = weekIncomplete;
    }, function (translationId) {
        $scope.weekIncomplete = translationId;
    });

    $translate('WEEKLY_TIMESHEET_HOLIDAY').then(function (holiday) {
        $scope.holiday = holiday;
    }, function (translationId) {
        $scope.holiday = translationId;
    });

    $translate('WEEKLY_TIMESHEET_EQUIVALENT').then(function (equivalent) {
        $scope.equivalent = equivalent;
    }, function (translationId) {
        $scope.equivalent = translationId;
    });

    AuthService.me()
        .then((currentUser) => {
            $scope.currentUser = currentUser;
        })

    if ($scope.$parent.selectedUser){
        init($scope.$parent.selectedUser);
    }

    $scope.$on('user:selected', function(){
        init($scope.$parent.selectedUser);
    });

    function init(user){
        //Init the selected date with the first day of the week
        $scope.selectedDate = CalendarService.getWeekOfYear($stateParams.year, $stateParams.week);

        var from = CalendarService.getWeekOfYear($stateParams.year, $stateParams.week);
        var to = CalendarService.getWeekOfYear($stateParams.year, $stateParams.week).add(4, 'days');

        $scope.week = CalendarService.getWeek($stateParams.year, $stateParams.week);

        $scope.timerecords = Timerecord.query({
            memberId: user.id,
            from: from.format('YYYY-MM-DD'),
            to: to.format('YYYY-MM-DD')
        });

        $timeout(function(){
            Timerecord.firstIncompleteDay({
                    memberId: user.id
                }).$promise.then(function (day) {
                var firstIncompleteDay = moment(day.date);
                $scope.firstIncompleteWeek = CalendarService.getWeekOfYear(firstIncompleteDay.year(), firstIncompleteDay.week());
                $scope.isFirstIncompleteWeek = firstIncompleteDay.week() == $stateParams.week && firstIncompleteDay.year() == $stateParams.year;
            });
        });
    }


    /** The year can be determined by the year of the thursday of the week
     * @see wikipedia https://fr.wikipedia.org/wiki/Semaine_1
     */
    function getYear(date){
        if (date){
            var week = moment(date).startOf('week');
            return week.add(3, 'days').year();
        }
        else return null;
    }

    function getWeek(date){
        if (date){
            var week = moment(date).startOf('week');
            return week.week();
        }
        else return null;
    }

    $scope.getPrevious = function(){
        var previousWeek = CalendarService.getWeekOfYear($stateParams.year, $stateParams.week).subtract(1, 'weeks');
        return { userId: $stateParams.userId, year: getYear(previousWeek), week: previousWeek.week() }
    };

    $scope.getNext = function(){
        var nextWeek = CalendarService.getWeekOfYear($stateParams.year, $stateParams.week).add(1, 'weeks');
        return { userId: $stateParams.userId, year: getYear(nextWeek), week: nextWeek.week() }
    };


    $scope.gotoWeek = function(){
        $state.go('.', { userId: $stateParams.userId, year: getYear($scope.selectedDate), week: getWeek($scope.selectedDate) })
    }

    function totalDuration(day){
        var totalDuration = 0;
        if ($scope.timerecords){
            var dailyTimerecords = $filter('day')($scope.timerecords,day);
            dailyTimerecords.forEach(function(record){
                if (!record.dragged){
                    totalDuration += record.duration;
                }
            });
        }
        return totalDuration;
    }

    function isDayFull(day){
        return (100 - totalDuration(day)) == 0;
    }

    function isDurationFull(day, duration){
        return (100 - totalDuration(day) < duration);
    }

    $scope.isDayFull = isDayFull;
    $scope.isDurationFull = isDurationFull;

    $scope.dragOverDuration = function(event, theMoment, duration) {

        //Check if the totalDuration has been reached
        if (isDurationFull(theMoment, duration)) {
            event.dataTransfer.dropEffect = 'none';
        }
        // Or will be greater if dropping the node
        else if ((totalDuration(theMoment.day()) + duration) > 100) {
            event.dataTransfer.dropEffect = 'none';
        }

    }

    $scope.dropOnDuration = function(event, theMoment, duration) {

        var dataAndType = toDragDataAndType(event);
        var dataType = dataAndType.type;
        var dropped = dataAndType.data;

        //Check that the target day can be modified
        if (dataType == "application/x-timerecord.timerecord+json")
        {
            //The dropped object is a timerecord
            _updateOrIncreaseTimerecord(theMoment, duration, dropped);

        } else {
            //The dropped object is an activity
            _createOrIncreaseTimerecord(theMoment, duration, dropped);
        }

    }

    $scope.dragOverWeek = function(event) {

        var type = toDragType(event);

        if (type != "application/x-timerecord.activity+json") {
            event.dataTransfer.dropEffect = 'none';
        } else {
            // check if there are free duration
            var total = 0;
            $scope.timerecords.forEach(function(timerecord){
                total += timerecord.duration;
            });

            if (total == 500){
                event.dataTransfer.dropEffect = 'none';
            }
        }
    }

    function _findExistingTimerecord(theMoment, activity){
        var dayRecords = $filter('day')($scope.timerecords, theMoment.day());
        return dayRecords.find((timerecord) => timerecord.activity.id === activity.id);
    }

    /**
     *  Update a timerecord or increase an existing timerecord from it
     */
    function _updateOrIncreaseTimerecord(theMoment, duration, timerecord){

        var updatedTimerecord = $scope.timerecords.filter(function(element){
            return element.id === timerecord.id;
        })[0];

        var isSameDay = theMoment.isSame(moment.utc(updatedTimerecord.date))
        //Update only if the date or duration is different
        if (!isSameDay || duration != updatedTimerecord.duration) {

            var existingTimerecord = _findExistingTimerecord(theMoment, updatedTimerecord.activity);

            if (!isSameDay && existingTimerecord){
                existingTimerecord.duration += duration;
                existingTimerecord.comment += updatedTimerecord.comment;

                Timerecord.update(existingTimerecord, function(){
                    Timerecord.remove(updatedTimerecord, function(){}, function(err){
                        toastr.error(err.data);
                        //TODO : refresh timerecords model
                    });
                }, function(err){
                    toastr.error(err.data);
                    //TODO : refresh timerecords model
                });

                var idx = $scope.timerecords.indexOf(updatedTimerecord);
                $scope.timerecords.splice(idx, 1);


            }else{
                updatedTimerecord.date = theMoment.format();
                updatedTimerecord.duration = duration;
                Timerecord.update(updatedTimerecord, function(){}, function(err){
                    toastr.error(err.data);
                });
            }

        }
        updatedTimerecord.dragged = false;
    }

    /**
     *  Create or increase an existing timerecord from an activity
     */
    function _createOrIncreaseTimerecord(theMoment, duration, activity){
        // search if the same activity is already set for this day
        var timerecord;

        var existingTimerecord = _findExistingTimerecord(theMoment, activity);

        if (existingTimerecord){
            timerecord = existingTimerecord;

            //Increase the existing duration
            timerecord.duration += duration;

            //Save the timerecord
            Timerecord.update(timerecord, function (result) {
                timerecord.id = result.id;
            }, function (err) {
                toastr.error(err.data);
                //TODO : refresh timerecords model
            });
        }else{
            //Create a new timerecord
            timerecord = new Timerecord({
                member: $scope.selectedUser,
                date: theMoment.format(),
                activity: activity,
                duration: duration,
                comment : '',
            });

            $scope.timerecords.push(timerecord);

            //Save the timerecord
            Timerecord.save(timerecord, function (result) {
                timerecord.id = result.id;
            }, function (err) {
                toastr.error(err.data);
                //TODO : refresh timerecords model
            });
        }

        

    }

    $scope.dropOnWeek = function(event) {

        var dataAndType = toDragDataAndType(event);
        var activity = dataAndType.data;

        //For each day of the week, fill with the dropped activity
        for (let dayNum in $scope.week.days){

            var day = $scope.week.days[dayNum];

            if (!day.isHoliday){
                var duration = 100 - totalDuration(day.moment.day());

                if (duration > 0){
                    _createOrIncreaseTimerecord(day.moment, duration, activity);
                }
            }
        }

    }

    $scope.dragOverTrash = function(event){
        var type = toDragType(event);

        //If the source is not a timerecord, drop is not allowed
        if (type != "application/x-timerecord.timerecord+json")
        {
            event.dataTransfer.dropEffect = 'none';
        }
    }

    $scope.dropOnTrash = function(event){

        var jsonData = toDragDataAndType(event).data;

        var timerecord = $scope.timerecords.filter(function(element){
            return element.id === jsonData.id;
        })[0];

        var idx = $scope.timerecords.indexOf(timerecord);
        $scope.timerecords.splice(idx, 1);

        Timerecord.delete(timerecord, function(){
        }, function(){
            $scope.timerecords.push(timerecord);
        });
    }

    $scope.editComment = function(originalTimerecord)
    {
        var timerecord = angular.copy(originalTimerecord);
        //Get the timerecord corresponding to the source event
        var modalInstance = $modal.open({
                animation: $scope.animationsEnabled,
                template: updateCommentModalTemplate,
                windowClass : "update-comment-modal",
                controller: 'UpdateCommentModalController',
                resolve: {
                timerecord: function () {
                    return timerecord;
                },
                memberId: function(){
                    return $scope.selectedUser.id;
                }
                }
            });

        modalInstance.result.then(function (updatedTimerecord) {
                Timerecord.update(updatedTimerecord, function(){
                    angular.copy(updatedTimerecord, originalTimerecord);
                });
            }, function () {
                //Modal dismissed
            });
    }

    /** Date picker **/
    $scope.status = {};

    $scope.open = function() {
        $scope.status.opened = true;
    };

    $scope.disabled = function(date, mode) {
        return ( mode === 'day' && ( date.getDay() === 0 || date.getDay() === 6 ) );
        };

}