LoginController.$inject = [
    '$scope',
    '$stateParams',
    'AuthService',
    'Timesheet'
];

export function LoginController($scope, $stateParams, AuthService, Timesheet) {

    $scope.credentials = {};
    $scope.isLoading = false;
    $scope.message = $stateParams.error;

    if (AuthService.isAuthenticated()) {
        Timesheet.gotoTimesheet()
    }

    $scope.login = function () {
        $scope.message = null;
        $scope.passwordError = null;
        $scope.emailError = null;
        $scope.isLoading = true;

        AuthService.login($scope.credentials)
            .then(() => Timesheet.gotoTimesheet())
            .catch(err => {
                $scope.isLoading = false;
                if (err.status === 400) {
                    $scope.passwordError = 'Le mot de passe est invalide';
                } else if (err.status === 404) {
                    $scope.emailError = 'L\'adresse email est invalide';
                }
            });
    }

}