export function PasswordVerify() {
  return {
     require: "ngModel",
     scope: {
       passwordVerify: '='
     },
     link: function (scope, elem, attrs, model) {
      if (!attrs.passwordVerify) {
          // eslint-disable-next-line no-console
          console.error('passwordVerify expects a model as an argument!');
          return;
      }
      scope.$parent.$watch(attrs.passwordVerify, function (value) {
          // Only compare values if the second ctrl has a value.
          if (model.$viewValue !== undefined && model.$viewValue !== '') {
              model.$setValidity('passwordVerify', value === model.$viewValue);
          }
      });
      model.$parsers.push(function (value) {
          // Mute the passwordVerify error if the second ctrl is empty.
          if (value === undefined || value === '') {
              model.$setValidity('passwordVerify', true);
              return value;
          }
          var isValid = value === scope.$parent.$eval(attrs.passwordVerify);
          model.$setValidity('passwordVerify', isValid);
          return isValid ? value : undefined;
      });
  }
  };
}