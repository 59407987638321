import angular from 'angular';

import { AddMemberModalController } from './controllers/add-member-modal.controller';
import { ChangePasswordModalController } from './controllers/change-password-modal.controller';
import { MembersController } from './controllers/members.controller';

import { ChangePasswordModalService } from './services/change-password-modal.service';
import { MemberModalService } from './services/member-modal.service';
import { MemberService } from './services/member.service';

const members = angular.module('members',
    [
        'ngResource',
        'activities'
    ]
);

members.controller('AddMemberModalController', AddMemberModalController);
members.controller('ChangePasswordModalController', ChangePasswordModalController);
members.controller('MembersController', MembersController);

members.factory('ChangePasswordModal', ChangePasswordModalService);
members.factory('MemberModal', MemberModalService);
members.factory('Member', MemberService);

export const MembersModule = members;